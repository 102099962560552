import React, { useEffect, useState } from "react";
import Logo from "./assets/images/logo-no-bg-text-white.png";
import CloseSvg from "./assets/svg/closeSvg";
import HamburgerSvg from "./assets/svg/hamburgerSvg";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [rootpathName, setRootpathName] = useState("/");

  useEffect(() => {
    const listPathName = location?.pathname?.split("/");

    if (listPathName.length > 1) {
      setRootpathName(listPathName[1] || "/");
    } else {
      setRootpathName("/");
    }
  }, [location]);

  return (
    <>
      <header className="fixed top-0 w-full z-[60] bg-[rgb(var(--background-red))] transition-all shadow-md py-2">
        <nav className="max-w-screen-xl px-6 xl:px-0 mx-auto grid grid-flow-col">
          <div className="col-start-1 col-end-2 flex items-center">
            <span className="flex lg:hidden">
              {open ? (
                <CloseSvg
                  className="text-white mr-4"
                  size={25}
                  fill="#fff"
                  onClick={() => setOpen(false)}
                />
              ) : (
                <HamburgerSvg
                  className="text-white mr-4"
                  onClick={() => setOpen(true)}
                />
              )}
            </span>

            <a href="/">
              <img
                src={Logo}
                alt="logo"
                height={50}
                width={30}
                className="w-[30px] h-[50]"
              />
            </a>
          </div>
          <div className="col-start-2 col-end-11 text-white-500 items-center">
            <ul className="hidden lg:flex justify-center pl-0">
              <a
                href="/"
                className={
                  "text-white font-medium px-4 py-2 mx-2 cursor-pointer no-underline nav-header" +
                  (["", "/"].includes(rootpathName) ? "-active" : "")
                }
              >
                TRANG CHỦ
              </a>
              <a
                href="/about"
                className={
                  "text-white font-medium px-4 py-2 mx-2 cursor-pointer no-underline nav-header" +
                  (rootpathName === "about" ? "-active" : "")
                }
              >
                ABOUT US
              </a>
              <a
                href="/ticket"
                className={
                  "text-white font-medium px-4 py-2 mx-2 cursor-pointer no-underline nav-header" +
                  (rootpathName === "ticket" ? "-active" : "")
                }
              >
                VÉ
              </a>
              <a
                href="/blog"
                className={
                  "text-white font-medium px-4 py-2 mx-2 cursor-pointer no-underline nav-header" +
                  (rootpathName === "blog" ? "-active" : "")
                }
              >
                BLOG
              </a>
            </ul>
          </div>
          <div className="col-start-11 col-end-12 font-medium flex justify-end items-center">
            {/* <Link href="/">
              <a className="text-black-600 mx-2 sm:mx-4 capitalize tracking-wide hover:text-orange-500 transition-all">
                  Sign In
              </a>
            </Link> */}
            {/* <ButtonOutline>Sign Up</ButtonOutline> */}
            {/* <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                width="25"
                height="25"
                fill="none"
                className="cursor-pointer"
              >
                <path
                  d="M8.33337 8.33325H13.0218C13.7718 8.33325 14.1467 8.33325 14.418 8.54505C14.6893 8.75684 14.7802 9.12062 14.9621 9.84818L15.9092 13.6367C16.273 15.0918 16.4549 15.8194 16.9974 16.243C17.54 16.6666 18.2899 16.6666 19.7898 16.6666H20.8334"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M37.5 35.4165H15.2749C14.0086 35.4165 13.3755 35.4165 13.0762 35.0012C12.7769 34.5859 12.9771 33.9853 13.3775 32.784L13.6716 31.9016C14.1116 30.5816 14.3317 29.9215 14.8554 29.544C15.3792 29.1665 16.0749 29.1665 17.4664 29.1665H29.1667"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M32.9446 29.1665H20.0552C18.4369 29.1665 17.6277 29.1665 17.0682 28.6925C16.5087 28.2186 16.3757 27.4204 16.1096 25.8241L15.1656 20.1597C14.8953 18.5382 14.7602 17.7274 15.2095 17.1969C15.6589 16.6665 16.4808 16.6665 18.1248 16.6665H38.4306C39.881 16.6665 40.6062 16.6665 40.8993 17.1408C41.1924 17.615 40.8681 18.2637 40.2195 19.5609L36.5223 26.9554C35.9844 28.0312 35.7154 28.5691 35.2321 28.8678C34.7488 29.1665 34.1474 29.1665 32.9446 29.1665Z"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                ></path>
                <ellipse
                  cx="35.4167"
                  cy="41.6666"
                  rx="2.08333"
                  ry="2.08333"
                  fill="white"
                ></ellipse>
                <ellipse
                  cx="18.75"
                  cy="41.6666"
                  rx="2.08333"
                  ry="2.08333"
                  fill="white"
                ></ellipse>
              </svg>
              <span className="text-red w-[20px] h-[20px] rounded-xl bg-white text-center absolute text-[11px] flex items-center text-red-700 justify-center ml-[20px]">
                99
              </span>
            </div> */}
          </div>
        </nav>
        {open && (
          <div className=" w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 mt-4 bg-[rgb(var(--background-red))]">
              <li>
                <a
                  href="/"
                  className="block py-3 px-3 text-white bg-[rgb(var(--background-red))] rounded md:bg-transparent no-underline"
                >
                  TRANG CHỦ
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  className="block py-3 px-3 text-white bg-[rgb(var(--background-red))] rounded md:bg-transparent no-underline"
                >
                  ABOUT US
                </a>
              </li>
              <li>
                <a
                  href="/ticket"
                  className="block py-3 px-3 text-white bg-[rgb(var(--background-red))] rounded md:bg-transparent no-underline"
                >
                  VÉ
                </a>
              </li>
              <li>
                <a
                  href="/blog"
                  className="block py-3 px-3 text-white bg-[rgb(var(--background-red))] rounded md:bg-transparent no-underline"
                >
                  BLOG
                </a>
              </li>
            </ul>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
