import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../api";
import { formatDateFm, formatDateNotTime } from "../../utils/formatter";
export default function CompletedOrder() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = async () => {
    if (id) {
      const res = await API.getOrderDetail(id);
      if (res.success) {
        setOrder(res.data);
      }
    }
  };

  return (
    <section class="py-24 relative">
      <div class="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
        <h2 class="font-manrope font-bold text-4xl leading-10 text-black text-center">
          Đặt vé thành công
        </h2>
        <p class="mt-4 font-normal text-lg leading-8 text-gray-500 mb-11 text-center">
          Cảm ơn bạn đã đặt vé, bạn có thể kiểm tra tóm tắt thông tin vé đã đặt
          bên dưới
        </p>
        <div class="main-box border border-gray-200 rounded-xl pt-6 max-w-xl max-lg:mx-auto lg:max-w-full">
          <div class="flex flex-col lg:flex-row lg:items-center justify-between px-6 pb-6 border-b border-gray-200">
            <div class="data">
              <p class="font-semibold text-base leading-7 text-black">
                Mã vé:{" "}
                <span class="text-indigo-600 font-medium">
                  {order?.order_code}
                </span>
              </p>
              <p class="font-semibold text-base leading-7 text-black mt-4">
                Ngày đặt vé:{" "}
                <span class="text-emerald-500 font-medium">
                  {formatDateFm(order?.created_at)}
                </span>
              </p>
              <p class="font-semibold text-base leading-7 text-black mt-4">
                Tên khách hàng:{" "}
                <span class="font-medium">{order?.full_name}</span>
              </p>
              <p class="font-semibold text-base leading-7 text-black mt-4">
                Email: <span class="font-medium">{order?.email}</span>
              </p>
              <p class="font-semibold text-base leading-7 text-black mt-4">
                Số điện thoại: <span class="font-medium">{order?.phone}</span>
              </p>
            </div>
          </div>
          {order?.order_items.map((item, index) => {
            return (
              <div
                class="w-full px-3 min-[400px]:px-6"
                key={"order-item-" + index}
              >
                <div class="flex flex-col lg:flex-row items-center py-6 border-b border-gray-200 gap-6 w-full">
                  <div class="img-box max-lg:w-full">
                    <img
                      src={item.url_image}
                      alt="Premium"
                      class="aspect-square w-full lg:max-w-[140px] rounded-xl object-cover"
                    />
                  </div>
                  <div class="flex flex-row items-center w-full ">
                    <div class="grid grid-cols-1 lg:grid-cols-2 w-full">
                      <div class="flex items-center">
                        <div class="">
                          <h2 class="font-semibold text-xl leading-8 text-black mb-3">
                            {item.ticket_name}
                          </h2>
                          <div class="flex items-center ">
                            <p class="font-medium text-base leading-7 text-black ">
                              Số lượng:{" "}
                              <span class="text-gray-500">{item.quantity}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="grid grid-cols-5">
                        <div class="col-span-5 lg:col-span-1 flex items-center max-lg:mt-3">
                          <div class="flex gap-3 lg:block">
                            <p class="font-medium text-sm leading-7 text-black">
                              Giá vé
                            </p>
                            <p class="lg:mt-4 font-medium text-sm leading-7 text-indigo-600">
                              {item.amount} vnd
                            </p>
                          </div>
                        </div>
                        <div class="col-span-5 lg:col-span-2 flex items-center max-lg:mt-3">
                          <div class="flex gap-3 lg:block">
                            <p class="font-medium text-sm whitespace-nowrap leading-6 text-black text-center">
                              Ngày tham dự
                            </p>
                            <p class="font-medium text-sm leading-6 whitespace-nowrap py-0.5 px-3 rounded-full lg:mt-3 bg-emerald-50 text-emerald-600">
                              {formatDateNotTime(item.day)}
                            </p>
                          </div>
                        </div>
                        <div class="col-span-5 lg:col-span-2 flex items-center max-lg:mt-3 ">
                          <div class="flex gap-3 lg:block">
                            <p class="font-medium text-sm whitespace-nowrap leading-6 text-black text-center">
                              Thời gian diễn ra
                            </p>
                            <p class="font-medium text-sm leading-6 whitespace-nowrap py-0.5 px-3 rounded-full lg:mt-3 bg-emerald-50 text-emerald-600">
                              {item.from_time?.slice(0, 5) +
                                " - " +
                                item.to_time?.slice(0, 5)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div class="w-full border-t border-gray-200 px-6 flex flex-col items-center justify-between ">
            <div class="font-semibold text-lg text-black py-6">
              Tổng tiền tạm tính:{" "}
              <span class="text-indigo-600">{order?.amount} VND</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
