const CalendarSvg = ({ size = "20", fill = "none", ...props }) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    strokeLinejoin="round"
    strokeLinecap="round"
    strokeWidth="2"
    stroke="currentColor"
  >
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="16" y1="2" x2="16" y2="6"></line>
    <line x1="8" y1="2" x2="8" y2="6"></line>
    <line x1="3" y1="10" x2="21" y2="10"></line>
  </svg>
);

export default CalendarSvg;
