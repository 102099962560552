import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  return (
    <div>
      <Header />
      <main className="max-w-screen-xl w-full mx-auto pt-[75px]">
        <Outlet /> {/* Đây là nơi các component con sẽ được render */}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
