import React from "react";
import Carousel from "react-bootstrap/Carousel";

export default function CarouselBanner({
  classCarouselBanner = "",
  listImage = ["1"],
}) {
  return (
    <Carousel>
      <Carousel.Item>
        <picture>
          <source
            srcSet="https://phatbaonghiemtran.com/api/images/banners/banner-pbnt.jpg"
            media="(max-width: 480px)"
            className="h-full w-full object-cover "
          />
          <img
            src="https://phatbaonghiemtran.com/api/images/banners/banner-phnt-web.jpg"
            alt="img"
            className="h-full w-full object-cover "
          />
        </picture>
      </Carousel.Item>
    </Carousel>
  );
}
