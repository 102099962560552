const CloseSvg = ({ size = "20", fill = "#7D7D80", ...props }) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
  >
    <path
      d="M18.2997 5.70875C17.9097 5.31875 17.2797 5.31875 16.8897 5.70875L11.9997 10.5888L7.10973 5.69875C6.71973 5.30875 6.08973 5.30875 5.69973 5.69875C5.30973 6.08875 5.30973 6.71875 5.69973 7.10875L10.5897 11.9988L5.69973 16.8887C5.30973 17.2787 5.30973 17.9087 5.69973 18.2987C6.08973 18.6887 6.71973 18.6887 7.10973 18.2987L11.9997 13.4087L16.8897 18.2987C17.2797 18.6887 17.9097 18.6887 18.2997 18.2987C18.6897 17.9087 18.6897 17.2787 18.2997 16.8887L13.4097 11.9988L18.2997 7.10875C18.6797 6.72875 18.6797 6.08875 18.2997 5.70875Z"
      fill={fill}
    />
  </svg>
);

export default CloseSvg;
