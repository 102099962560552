import React, { useEffect, useRef, useState } from "react";
import API from "../../api";
import ItemTicket from "../../components/TicketInformation/itemTicket";
import TicketInfor from "../../components/Popups/ticketInfor";

const Ticket = () => {
  const [ticket, tickets] = useState([]);
  const didFetch = useRef(false);
  const [isVisible, setIsVisible] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);

  useEffect(() => {
    if (!didFetch.current) {
      didFetch.current = true; // Đảm bảo API chỉ được gọi một lần
      API.getTickets("trien-lam-phat-giao").then((res) => {
        if (res.success) {
          tickets(res.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    setIsVisible(false);
  };

  const onOpenInfor = (infTicket) => {
    setIsVisible(true);
    setDataDetail(infTicket);
  };

  return (
    <div>
      <h1 className="uppercase text-3xl text-center font-bold my-3 text-[rgb(var(--background-red))]">
        Danh sách Vé
      </h1>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 my-6 mx-6 xl:mx-0">
        {ticket.map((item, index) => {
          return (
            <ItemTicket
              item={item}
              key={"ticket-item-" + index}
              onOpenInfor={onOpenInfor}
            />
          );
        })}

        <TicketInfor
          isVisible={isVisible}
          onClose={onClose}
          data={dataDetail}
        />
      </div>
    </div>
  );
};

export default Ticket;
