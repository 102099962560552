"use client";
import parse from "html-react-parser";
import { useEffect } from "react";
import styles from "./styles.module.css";
import { numberWithComma } from "../../utils/formatter";

export default function TicketInfor({ isVisible = false, onClose, data }) {
  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("overflow-hidden");
      const overlay = document.getElementById("ticket-info-popup");

      if (overlay) {
        overlay.addEventListener("click", function (event) {
          if (event.target === this) {
            onClose();
          }
        });
      }
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  });

  console.log(data);
  console.log(data?.selling_price);

  console.log(data?.selling_price !== data?.price);

  return (
    <>
      {isVisible && (
        <div
          id="ticket-info-popup"
          tabIndex={-1}
          aria-hidden="true"
          className="overflow-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full bg-gray-900/50"
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full m-auto">
            <div className="relative bg-white rounded-lg shadow mt-[20vh]">
              <div className="p-4 md:p-5 space-y-4">
                <p className="text-base leading-relaxed text-gray-500 ">
                  <h1 className={styles.title}>{data?.ticket_name}</h1>
                  {data?.selling_price !== data?.price ? (
                    <div className="flex items-end mt-4">
                      <div className="text-[#ffcc00] -tracking-wide font-normal text-md uppercase mr-4 line-through">
                        {numberWithComma(data?.price)} đ
                      </div>
                      <div className="text-[rgb(var(--background-red))] -tracking-wide font-semibold text-2xl uppercase">
                        {numberWithComma(data?.selling_price)} đ
                      </div>
                    </div>
                  ) : (
                    <div className="text-[rgb(var(--background-red))] -tracking-wide font-semibold text-2xl uppercase mt-4">
                      {numberWithComma(data?.selling_price)} đ
                    </div>
                  )}
                </p>

                <div className="text-sm">
                  {parse(data?.ticket_information || "")}
                </div>
              </div>
              <div className={styles.coverButton}>
                <button
                  data-modal-hide="default-modal"
                  type="button"
                  className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-4 focus:ring-gray-100 mr-3"
                  onClick={() => onClose()}
                >
                  Đóng
                </button>
                <a
                  href={`/product/${data?.ticket_code}`}
                  data-modal-hide="default-modal"
                  type="button"
                  className="text-white uppercase bg-[rgb(var(--background-red))] hover:bg-[rgb(var(--background-red2))]  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Đặt vé ngay
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
