export default function InforDescrition() {
  return (
    <div className="bg-gray-200 p-6 my-6 rounded  mx-6 xl:mx-0">
      <div className="text-[rgb(var(--background-red))] -tracking-wide font-semibold text-xl leading-7 uppercase h-[60px] pt-2 dot-2line text-center xl:text-left">
        THÔNG TIN TRIỂN LÃM
      </div>
      <div>
        <b>Phật Bảo Nghiêm Trấn</b> là không gian triển lãm sắp đặt
        (installation art) chuyên về lịch sử, văn hóa Phật giáo lần đầu tiên có
        mặt tại Việt Nam với quy mô của bảo tàng mini. Dưới sự dẫn dắt của âm
        thanh, ánh sáng và mùi hương cùng tour guide là những hành giả - thiền
        sinh, chắc chắn bạn sẽ gặp một nét Phật giáo thật khác mà không nơi nào
        có được.
      </div>
      <div>
        Tour thưởng lãm kéo dài tối đa 90 phút, quý vị sẽ được trải nghiệm hành
        trình ngược chiều thời gian về lại đất Phật hơn 2000 năm trước một cách
        trọn vẹn, ý nghĩa.
      </div>
    </div>
  );
}
