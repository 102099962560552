import CalendarSvg from "../../assets/svg/calendarSvg";
import ClockSvg from "../../assets/svg/clockSvg";
import Hourglass from "../../assets/svg/hourglass";
import MapSvg from "../../assets/svg/mapSvg";

export default function InforTime() {
  return (
    <div className=" my-6">
      <div className="uppercase text-[rgb(var(--background-red))] my-6 font-bold text-xl text-center">
        THÔNG TIN VÉ THAM QUAN
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-4 gap-4">
        <div className="grid xl:flex justify-center text-center gap-2 justify-items-center">
          <CalendarSvg color="#c5272e" size={40} />
          <div className=" xl:text-start">
            <div className="uppercase text-[rgb(var(--background-red))] text-sm leading-5 font-semibold">
              Thời gian dự kiến triển lãm
            </div>
            <div className="text-xs">Từ ngày 2024-07-25 09:26:26</div>
          </div>
        </div>
        <div className="grid xl:flex justify-center text-center gap-2 justify-items-center">
          <ClockSvg color="#c5272e" size={40} />
          <div className=" xl:text-start">
            <div className="uppercase text-[rgb(var(--background-red))] text-sm leading-5 font-semibold">
              Thời gian mở cửa
            </div>
            <div className="text-xs">09:30 - 22:00</div>
          </div>
        </div>
        <div className="grid xl:flex justify-center text-center gap-2 justify-items-center">
          <Hourglass fill="#c5272e" size={40} />
          <div className=" xl:text-start">
            <div className="uppercase text-[rgb(var(--background-red))] text-sm leading-5 font-semibold">
              Thời gian tham quan
            </div>
            <div className="text-xs">Khoảng 90 phút</div>
          </div>
        </div>
        <div className="grid xl:flex justify-center text-center gap-2 justify-items-center">
          <MapSvg fill="#c5272e" size={50} />
          <div className=" xl:text-start">
            <div className="uppercase text-[rgb(var(--background-red))] text-sm leading-5 font-semibold">
              Địa điểm triển lãm
            </div>
            <div className="text-xs">
              11A - Tôn Đức Thắng, P. Bến Nghé, Q1, TP.HCM
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
