import React from "react";

const Blog = () => {
  const list = [
    {
      name: "The Future of Renewable Energy: Innovations and Challenges Ahead",
      url_mage:
        "https://api-prod-minimal-v610.pages.dev/assets/images/cover/cover-1.webp",
      url: "#",
    },
    {
      name: "The Future of Renewable Energy: Innovations and Challenges Ahead",
      url_mage:
        "https://api-prod-minimal-v610.pages.dev/assets/images/cover/cover-2.webp",
      url: "#",
    },
    {
      name: "The Future of Renewable Energy: Innovations and Challenges Ahead",
      url_mage:
        "https://api-prod-minimal-v610.pages.dev/assets/images/cover/cover-2.webp",
      url: "#",
    },
    {
      name: "The Future of Renewable Energy: Innovations and Challenges Ahead",
      url_mage:
        "https://api-prod-minimal-v610.pages.dev/assets/images/cover/cover-2.webp",
      url: "#",
    },
    {
      name: "The Future of Renewable Energy: Innovations and Challenges Ahead",
      url_mage:
        "https://api-prod-minimal-v610.pages.dev/assets/images/cover/cover-2.webp",
      url: "#",
    },
    {
      name: "The Future of Renewable Energy: Innovations and Challenges Ahead",
      url_mage:
        "https://api-prod-minimal-v610.pages.dev/assets/images/cover/cover-2.webp",
      url: "#",
    },
    {
      name: "The Future of Renewable Energy: Innovations and Challenges Ahead",
      url_mage:
        "https://api-prod-minimal-v610.pages.dev/assets/images/cover/cover-2.webp",
      url: "#",
    },
  ];
  return (
    <div className="px-6 md:px-0">
      <h1 className="text-center text-2xl my-4 font-semibold">BLOG</h1>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-20">
        {list?.map((data, index) => {
          return (
            <div
              key={"blog-list-item-" + index}
              // href={data.url}
              className={
                "bg-white text-black bg-none relative shadow-[rgba(145,158,171,0.2)_0px_0px_2px_0px,rgba(145,158,171,0.12)_0px_12px_24px_-4px] z-0 transition-shadow duration-300 ease-in-out overflow-hidden rounded-2xl h-[360px] " +
                (index === 0 ? " md:col-span-2" : "")
              }
            >
              <span className="z-[1] w-full h-full absolute bg-[#141a21a3] left-0 top-0"></span>
              <span className="overflow-hidden relative align-bottom inline-block w-full h-[360px] blur-none transition-[filter] duration-[0.3s]">
                <img
                  src={data.url_mage}
                  alt="img"
                  className="opacity-100 transition-opacity duration-[0.3s] w-full h-full object-cover align-bottom rounded-md"
                />
              </span>
              <div className=" w-full z-[9] absolute p-6 bottom-0 text-white">
                <div
                  className={
                    "dot-2line " +
                    (index === 0 ? "text-2xl" : "text-2xl md:text-sm")
                  }
                >
                  {data.name}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Blog;
