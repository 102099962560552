import * as apiRequest from "./services/request";

const API = {
  // Exhibitions
  getExhibitionDetail: (code) => {
    const url = `/exhibitions/get_exhibition_detail.php?code=${code}`;
    return apiRequest.get(url);
  },
  getTicketDays: (ticket_code) => {
    const url = `/exhibitions/get_list_ticket_day.php?ticket_code=${ticket_code}`;
    return apiRequest.get(url);
  },
  getTicketTime: (day_code) => {
    const url = `/exhibitions/get_list_ticket_time.php?day_code=${day_code}`;
    return apiRequest.get(url);
  },
  // Tickets
  getTicketDetail: (code) => {
    const url = `/tickets/get_ticket_detail.php?code=${code}`;
    return apiRequest.get(url);
  },
  getTickets: (code) => {
    const url = `/tickets/get_tickets.php?code=${code}`;
    return apiRequest.get(url);
  },
  getAddress: () => {
    const url = `/list_address/get_location.php`;
    return apiRequest.get(url);
  },
  getProvinces: () => {
    const url = `/list_address/get_provinces.php`;
    return apiRequest.get(url);
  },
  getDistrict: (province_id) => {
    const url = `/list_address/get_district_province_id.php?province_id=${province_id}`;
    return apiRequest.get(url);
  },
  addToCart: (payload) => {
    const url = `/orders/add_to_cart.php`;
    return apiRequest.request({ url: url, method: "POST", payload });
  },
  getToCart: () => {
    const url = `/orders/get_to_cart.php`;
    return apiRequest.get(url);
  },
  createOrder: (payload) => {
    const url = `/orders/create_order.php`;
    return apiRequest.request({ url: url, method: "POST", payload });
  },
  getOrderDetail: (order_code) => {
    const url = `/orders/get_order_detail.php?order_code=${order_code}`;
    return apiRequest.get(url);
  },
};

export default API;
