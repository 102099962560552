import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home.js";
import AboutPage from "./pages/About.js";
import Layout from "./Layout";
import Blog from "./pages/blog/Blog.js";
import Ticket from "./pages/ticket/Tickets.js";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TicketDetail from "./pages/ticket/TicketDetail.js";
import Payment from "./pages/payments/page.js";
import CompletedOrder from "./pages/payments/completed_order.js";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="blog" element={<Blog />} />
          <Route path="ticket" element={<Ticket />} />
          <Route path="ticket/:id" element={<TicketDetail />} />
          <Route path="payments" element={<Payment />} />
          <Route path="completed_order/:id" element={<CompletedOrder />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
