const HamburgerSvg = ({ size = "25", fill = "none", ...props }) => (
  <svg
    {...props}
    viewBox="0 0 17 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1h15M1 7h15M1 13h15"
    ></path>
  </svg>
);

export default HamburgerSvg;
