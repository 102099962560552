import styles from "./styles.module.css";
import { Tooltip } from "@nextui-org/tooltip";

export default function TextButton({
  value = "",
  text,
  classItem = "",
  onClick,
  isActivity = false,
  disable = false,
}) {
  const onClickValue = () => {
    if (!disable) {
      onClick(value);
    }
  };

  const activityClass = (isActivityClass) => {
    if (disable) {
      return "!text-gray-200 !border-gray-200 !cursor-no-drop";
    }
    if (isActivityClass) {
      return styles.activity;
    }
    return "";
  };

  return (
    <div
      onClick={() => onClickValue()}
      className={`${styles.buttonText} ${activityClass(
        isActivity
      )} ${classItem}`}
    >
      {disable ? (
        <Tooltip
          showArrow={true}
          content={
            <div className="bg-neutral-700 px-3 rounded text-white text-sm">
              Đã kết thúc
            </div>
          }
          delay={0}
          closeDelay={0}
        >
          <div>{text}</div>
        </Tooltip>
      ) : (
        text
      )}
    </div>
  );
}
