import { useEffect, useState } from "react";
import API from "../../api";
import ItemTicket from "./itemTicket";
import TicketInfor from "../Popups/ticketInfor";

export default function TicketInformation({ exhibition_code }) {
  const [ticket, tickets] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);

  const onOpenInfor = (infTicket) => {
    setIsVisible(true);
    setDataDetail(infTicket);
  };

  useEffect(() => {
    API.getTickets(exhibition_code).then((res) => {
      if (res.success) {
        tickets(res.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    setIsVisible(false);
  };
  console.log(dataDetail);

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 my-6 mx-6 xl:mx-0">
      {ticket?.map((item, index) => {
        return (
          <ItemTicket
            item={item}
            key={"ticket-item-" + index}
            onOpenInfor={onOpenInfor}
          />
        );
      })}

      <TicketInfor isVisible={isVisible} onClose={onClose} data={dataDetail} />
    </div>
  );
}
