import React from "react";
import CarouselBanner from "../components/CarouselBanner/page";
import InforTime from "../components/Other/infor-time";
import TicketInformation from "../components/TicketInformation/page";
import InforDescrition from "../components/Other/infor-descrition";
const Home = () => {
  return (
    <>
      <CarouselBanner />
      <InforTime />
      <TicketInformation exhibition_code="trien-lam-phat-giao" />
      <InforDescrition />
    </>
  );
};

export default Home;
