import ProductInformation from "../../components/ProductInformation/page";
import { useEffect, useRef, useState } from "react";
import API from "../../api";
import CarouselBanner from "../../components/CarouselBanner/page";
import { useParams } from "react-router-dom";

export default function TicketDetail() {
  const { id } = useParams();
  const [ticket, setTicket] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [_, setBannerWebs] = useState([]);
  const didFetch = useRef(false);

  useEffect(() => {
    if (!didFetch.current) {
      didFetch.current = true; // Đảm bảo API chỉ được gọi một lần
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = async () => {
    if (id) {
      const res = await API.getTicketDetail(id);
      if (res.success) {
        setTicket(res.data);
        const bannerW = res.data.banner
          ?.filter((x) => x.type === "web")
          .map((x) => x.url_image);
        setBannerWebs(bannerW);
      }
    }
  };

  return (
    <>
      <CarouselBanner />
      <ProductInformation data={ticket} />
    </>
  );
}
