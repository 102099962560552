/* eslint-disable */

import numeral from "numeral";

const FORMAT_DECIMAL_NUMBER = "0,0.0000";

export const datetime = (input) => {
  const inputString =
    input?.match(/[+-]\d{2}:?\d{2}/) || input?.match(/Z$/)
      ? input
      : `${input}Z`;
  return input && new Date(inputString).toLocaleString("en-GB");
};

export const date = (input) => {
  const inputString =
    input?.match(/[+-]\d{2}:?\d{2}/) || input?.match(/Z$/)
      ? input
      : `${input}Z`;
  return input && new Date(inputString).toLocaleDateString("en-GB");
};

export function formatDateFm(date) {
  if (!date) return "";

  const newDate = new Date(date); // Tạo đối tượng Date từ input
  if (isNaN(newDate.getTime())) return ""; // Kiểm tra xem có phải là một ngày hợp lệ không

  const day = String(newDate.getDate()).padStart(2, "0"); // Lấy ngày, thêm 0 nếu cần
  const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Lấy tháng, thêm 0 nếu cần (tháng bắt đầu từ 0)
  const year = newDate.getFullYear(); // Lấy năm
  const hour = String(newDate.getHours()).padStart(2, "0");
  const minutes = String(newDate.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} ${hour}:${minutes}`;
}

export function formatDateNotTime(date) {
  if (!date) return "";

  const newDate = new Date(date);
  if (isNaN(newDate.getTime())) return "";

  const day = String(newDate.getDate()).padStart(2, "0");
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const year = newDate.getFullYear();

  return `${day}/${month}/${year}`;
}

export const humanize = (input) => {
  return (
    input &&
    input
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );
};

export const dashCase = (input) => {
  return (
    input &&
    input
      .split(" ")
      .map((word) => word.toLowerCase())
      .join("-")
  );
};

export const UpperCase = (input) => {
  return input && input.toUpperCase();
};

export function capitalizeFirstLetter(input) {
  return input && input.charAt(0).toUpperCase() + input.slice(1);
}

export function capitalizeFirstLetterOfEachWord(input) {
  return (
    input &&
    input
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );
}

export const number = (input) => {
  return Number.isInteger(input) ? input.toLocaleString() : "";
};

export const numberWithComma = (input) => {
  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const roundFloat = (input) => {
  return Math.round(input).toLocaleString();
};

export const floatNumber = (input) => {
  if (Number.isFinite(input)) {
    const truncInput = Math.trunc(input);
    const rem = input % truncInput;

    if (rem) {
      return input.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return truncInput.toLocaleString();
  }
};

export function vnd(value) {
  return numeral(Number(value) || 0).format("0,0.00");
}

export function decimalWithLimited4Digits(value) {
  if (value === null || value === "") {
    return "";
  }
  if (value.toString().match(/^\d+(?:\.\d{0,4})?$/)) {
    return value;
  }
  return Number(value).toFixed(4).toString();
}

export function autoFixedWith4Digit(value) {
  if (value === null || value === "") {
    return "";
  }
  if (value.toString().match(/^\d+(?:\.\d{0,4})?$/)) {
    return value;
  }
  return Number.parseFloat(value).toFixed(4);
}

export function autoFixedAndFormatNumberWith4Digit(x) {
  if (x === undefined || x === null || x === "") {
    return "";
  }
  return numeral(Number(x)).format("0,0.0000");
}

export function formatVndWith4Digit(x) {
  return numeral(Number(x) || 0).format(FORMAT_DECIMAL_NUMBER);
}

export function formatNumberWith4Digit(x) {
  if (Number(x)) {
    return numeral(Number(x)).format(FORMAT_DECIMAL_NUMBER);
  }
  return x;
}

export function getNumberOrObjectDecimal(input) {
  if (!input) {
    return 0.0;
  }
  if (Number.isNaN(input) && (input.coef || input.coef === 0)) {
    return input.coef / 10 ** -input.exp;
  }
  return Number(input);
}

export function displayInputAsNumberOrObjectDecimal(
  input,
  defaultValue = "0.0000"
) {
  if (!input) {
    return defaultValue;
  }
  if (Number.isNaN(input) && (input.coef || input.coef === 0)) {
    return numeral(Number(input.coef / 10 ** -input.exp) || 0).format(
      FORMAT_DECIMAL_NUMBER
    );
  }
  return numeral(Number(input)).format(FORMAT_DECIMAL_NUMBER);
}

export function sumDecimal(no1, no2) {
  if (!no1 || !no2) {
    return "0.0000";
  }

  if (Number.isNaN(no1) && (no1.coef || no1.coef === 0)) {
    return numeral(Number((no1.coef + no2.coef) / 10000) || 0).format(
      FORMAT_DECIMAL_NUMBER
    );
  }
  return numeral(Number(no1) + Number(no2)).format(FORMAT_DECIMAL_NUMBER);
}

export const numericWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function plainText(value) {
  return (value || "").replace(/(<([^>]+)>)/gi, "");
}

export function numberOrObjectDecimalWith4Digit(
  input,
  defaultValue = "0.0000"
) {
  if (!input) {
    return defaultValue;
  }
  if (Number.isNaN(input) && (input.coef || input.coef === 0)) {
    return numeral(Number(input.coef / 10 ** -input.exp) || 0).format("0.0000");
  }
  return input.replaceAll(",", "");
}

export function convertMonthFormat(input) {
  switch (input) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      return "";
  }
}

export function parseTimeHelper(time) {
  const hoursDiff = time.getHours() - time.getTimezoneOffset() / 60;
  const minutesDiff = (time.getHours() - time.getTimezoneOffset()) % 60;
  time.setHours(hoursDiff);
  time.setMinutes(minutesDiff);

  return time;
}

// input must be a number or string like number
export function numberWith4Digit(input, defaultValue = "0.0000") {
  if (!input) {
    return defaultValue;
  }
  const converted = Number.parseFloat(input).toFixed(4);
  return converted === "NaN" ? defaultValue : converted;
}

// input must be a number or string like number
export const StringOrNumberWithCommaAnd4Digits = (
  input,
  defaultValue = "0.0000"
) => {
  if (!input) {
    return defaultValue;
  }
  const sRoundedInput = Number.parseFloat(input).toFixed(4);
  if (sRoundedInput === "NaN") return input;

  const sParts = sRoundedInput.split(".");
  const firstPart =
    (sParts[0] && sParts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")) ||
    "0";

  const secondPart = sParts[1] || "0000";
  return firstPart + "." + secondPart;
};

export function numberFormat(numbers) {
  return `${new Intl.NumberFormat("vi-VN").format(numbers)}`;
}

export function partToStringExcel(stringValue) {
  if (!stringValue) {
    return "";
  }

  if (stringValue?.richText) {
    return stringValue?.richText?.map((e) => e.text).join("");
  }

  return stringValue.toString();
}

export function isValidDateTime(dateTimeString) {
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  return regex.test(dateTimeString);
}

export function formatDate(inputDate) {
  // Tạo đối tượng Date từ chuỗi input
  const date = new Date(inputDate);

  // Mảng chứa các ngày trong tuần bằng tiếng Việt
  const daysOfWeek = [
    "Chủ Nhật",
    "Thứ Hai",
    "Thứ Ba",
    "Thứ Tư",
    "Thứ Năm",
    "Thứ Sáu",
    "Thứ Bảy",
  ];

  // Lấy thứ, ngày và tháng từ đối tượng Date
  const dayOfWeek = daysOfWeek[date.getDay()];
  const day = date.getDate();
  const month = date.getMonth() + 1; // getMonth() trả về giá trị từ 0 đến 11, nên cần +1

  // Tạo chuỗi kết quả theo định dạng yêu cầu
  const formattedDate = `${dayOfWeek} - ${day}.${month}`;

  return formattedDate;
}

export function compareDateNowNotTime(dateString) {
  // Tạo đối tượng Date từ chuỗi input và chỉ lấy phần ngày
  const inputDate = new Date(dateString);
  inputDate.setHours(0, 0, 0, 0); // Đặt giờ, phút, giây và mili giây về 0

  // Lấy ngày hiện tại và chỉ lấy phần ngày
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Đặt giờ, phút, giây và mili giây về 0

  return inputDate >= currentDate;
}

export function isNowDate(dateString) {
  // Tạo đối tượng Date từ chuỗi input và chỉ lấy phần ngày
  const inputDate = new Date(dateString);
  inputDate.setHours(0, 0, 0, 0); // Đặt giờ, phút, giây và mili giây về 0

  // Lấy ngày hiện tại và chỉ lấy phần ngày
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Đặt giờ, phút, giây và mili giây về 0

  return inputDate <= currentDate;
}

export function passToday(dateString) {
  // Tạo đối tượng Date từ chuỗi input và chỉ lấy phần ngày
  const inputDate = new Date(dateString);
  inputDate.setHours(0, 0, 0, 0); // Đặt giờ, phút, giây và mili giây về 0

  // Lấy ngày hiện tại và chỉ lấy phần ngày
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Đặt giờ, phút, giây và mili giây về 0

  return inputDate >= currentDate;
}

export function formatTimeRange(from_time, to_time) {
  // Tách giờ, phút và giây từ chuỗi thời gian đầu vào
  const [from_hours, from_minutes] = from_time.split(":");
  const [to_hours, to_minutes] = to_time.split(":");

  // Tạo chuỗi định dạng mong muốn
  const formattedTimeRange = `${from_hours}:${from_minutes} - ${to_hours}:${to_minutes}`;

  return formattedTimeRange;
}

export function isTimeLessThanOrEqualToNow(inputTime) {
  // Tách giờ, phút và giây từ chuỗi thời gian đầu vào
  const [inputHours, inputMinutes, inputSeconds] = inputTime
    .split(":")
    .map(Number);

  // Tạo đối tượng Date cho thời gian hiện tại
  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentSeconds = now.getSeconds();

  // So sánh giờ, phút và giây
  if (inputHours < currentHours) return true;
  if (inputHours > currentHours) return false;
  if (inputMinutes < currentMinutes) return true;
  if (inputMinutes > currentMinutes) return false;
  return inputSeconds <= currentSeconds;
}
