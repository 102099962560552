import { numericWithCommas } from "../../utils/formatter";
import styles from "./styles.module.css";

export default function ItemTicket({ item, onOpenInfor }) {
  return (
    <div className={styles.cartItem}>
      <div className="w-full bg-white border border-gray-200 rounded-lg shadow">
        <picture>
          {item?.url_image_mobile && (
            <source srcSet={item.url_image_mobile} media="(max-width: 768px)" />
          )}
          {item?.url_image && (
            <source srcSet={item?.url_image} media="(min-width: 769px)" />
          )}

          <img src={item?.url_image} alt="Responsive" />
        </picture>
        <div className="px-5 pb-5">
          <h5 className={styles.title}>{item?.ticket_name}</h5>

          {item?.selling_price !== item?.price ? (
            <div className="flex items-end">
              <div className="text-[#ffcc00] -tracking-wide font-normal text-lg uppercase mr-4 line-through">
                {numericWithCommas(item?.price)} đ
              </div>
              <div className="text-[rgb(var(--background-red))] -tracking-wide font-semibold text-2xl uppercase mr-4">
                {numericWithCommas(item.selling_price)} đ
              </div>
            </div>
          ) : (
            <div className="text-[rgb(var(--background-red))] -tracking-wide font-semibold text-2xl uppercase mr-4">
              {numericWithCommas(item.price)} đ
            </div>
          )}

          <div className="grid grid-cols-2 mt-3">
            <div
              className="underline text-[#0078ff] uppercase text-sm leading-5 cursor-pointer"
              onClick={() => onOpenInfor(item)}
            >
              Thông tin gói
            </div>
            <a
              href={`/ticket/${item.ticket_code}`}
              className="main-button underline text-white font-medium text-sm leading-5 text-center bg-[rgb(var(--background-red))] px-4 py-2 rounded-xl"
            >
              Đặt vé ngay
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
