export function isEmpty(val) {
  return val === undefined ||
    val === null ||
    val.length < 1 ||
    val === "" ||
    val === "Invalid Date"
    ? true
    : false;
}

export function emailValidation(email) {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
  return !(!email || regex.test(email) === false);
}

export function phoneValidation(numPhone) {
  const regex = /^(84|0[3|5|7|8|9])+([0-9]{8})\b/i;
  return !(!numPhone || regex.test(numPhone) === false);
}
