import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { emailValidation, isEmpty, phoneValidation } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import API from "../../api";
import { formatDateNotTime, numberWithComma } from "../../utils/formatter";
import ErrorPopup from "../../components/Popups/errorPopup";

export default function Payment() {
  const navigate = useNavigate();
  // const [discount, setDiscount] = useState("");
  const [price, setPrice] = useState(0);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectProvince, setSelectProvince] = useState(0);
  const [selectDistrict, setSelectDistrict] = useState(0);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [tickets, setTicket] = useState([]);
  const [error, setError] = useState(false);
  // const [voucherApply, setVoucherApply] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // const onAplyVoucher = () => {
  //   if (voucherApply) {
  //     const ticketData = JSON.parse(localStorage.getItem("ticketData") || "");

  //     setTicket([ticketData]);
  //     setPrice(ticketData.amount * ticketData.quantity);
  //     setVoucherApply("");
  //     return;
  //   }
  //   if (discount === "TEST") {
  //     setPrice(0);
  //     setVoucherApply(discount);
  //     return;
  //   }
  // };

  const getDistrict = (province_id) => {
    API.getDistrict(province_id).then((res) => {
      if (res.success) {
        setDistricts(res.data);
      }
    });
  };

  const onChaneProvince = (province_id) => {
    setSelectProvince(province_id);
    getDistrict(province_id);
  };

  useEffect(() => {
    API.getProvinces().then((res) => {
      if (res.success) {
        setProvinces(res.data);
      }
    });

    getDistrict(79);

    if (localStorage.getItem("ticketData")) {
      const ticketData = JSON.parse(localStorage.getItem("ticketData") || "");
      console.log(ticketData);

      setTicket([ticketData]);
      setPrice(ticketData.amount * ticketData.quantity);
    }
  }, []);

  const onCompleted = () => {
    if (isEmpty(fullName)) {
      setError(true);
      return;
    }

    if (!phoneValidation(phone)) {
      setError(true);
      return;
    }

    if (!emailValidation(email)) {
      setError(true);
      return;
    }

    if (isEmpty(address)) {
      setError(true);
      return;
    }

    if (selectProvince < 1) {
      setError(true);
      return;
    }

    if (selectDistrict < 1) {
      setError(true);
      return;
    }

    const order = {
      full_name: fullName,
      email: email,
      phone: phone,
      address: address,
      province_id: selectProvince,
      district_id: selectDistrict,
      total_amount: price,
      tickets: JSON.stringify(tickets),
      voucher: "",
    };

    API.createOrder(order).then((res) => {
      if (res) {
        if (res.success) {
          navigate(`/completed_order/${res.data}`);
        } else {
          setErrorMessage(res.message);
        }
      }
    });
  };

  const onCloseError = () => {
    setErrorMessage("");
  };

  return (
    <div>
      <section className=" relative z-10 after:contents-[''] after:absolute after:z-0 after:h-full xl:after:w-1/3 after:top-0 after:right-0 after:bg-gray-50">
        <div className="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto relative z-10">
          <div className="grid grid-cols-12">
            <div className="col-span-12 xl:col-span-8 lg:pr-8 pt-14 pb-8 lg:py-24 w-full max-xl:max-w-3xl max-xl:mx-auto">
              <div className="flex items-center justify-between pb-8 border-b border-gray-300">
                <h2 className="font-manrope font-bold text-3xl leading-10 text-black">
                  Danh sách vé
                </h2>
              </div>
              <div className="grid grid-cols-12 mt-8 max-md:hidden pb-6 border-b border-gray-200">
                <div className="col-span-12 md:col-span-7">
                  <p className="font-normal text-lg leading-8 text-gray-400">
                    Thông tin vé
                  </p>
                </div>
                <div className="col-span-12 md:col-span-5">
                  <div className="grid grid-cols-5">
                    <div className="col-span-3">
                      <p className="font-normal text-lg leading-8 text-gray-400 text-center">
                        Quantity
                      </p>
                    </div>
                    <div className="col-span-2">
                      <p className="font-normal text-lg leading-8 text-gray-400 text-center">
                        Total
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {tickets.map((item, index) => {
                return (
                  <div
                    className="flex flex-col min-[500px]:flex-row min-[500px]:items-center gap-5 py-6  border-b border-gray-200 group"
                    key={"card-item-" + index}
                  >
                    <div className="w-full md:max-w-[126px]">
                      <img
                        src={item?.urlImage}
                        alt="img"
                        className="mx-auto rounded-xl object-cover"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-4 w-full">
                      <div className="md:col-span-2">
                        <div className="flex flex-col max-[500px]:items-center gap-3">
                          <h6 className="font-semibold text-base leading-7 text-black">
                            {item?.name}
                          </h6>
                          <span className="text-emerald-500 font-medium">
                            <span className="text-black">Thời gian:</span>{" "}
                            {formatDateNotTime(item?.ticketDate?.day)} -{" "}
                            {item?.ticketTime?.from_time.slice(0, 5) +
                              " - " +
                              item?.ticketTime?.to_time.slice(0, 5)}
                          </span>
                          <h6 className="font-medium text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-indigo-600">
                            {numberWithComma(item?.amount)}
                          </h6>
                        </div>
                      </div>
                      <div className="flex items-center justify-center h-full max-md:mt-3">
                        <div className="flex items-center h-full justify-center">
                          <p className="font-bold text-lg leading-8 text-gray-600 text-center transition-all duration-300 group-hover:text-indigo-600">
                            {numberWithComma(item?.quantity)}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center  max-md:mt-3 h-full justify-center">
                        <p className="font-bold text-lg leading-8 text-gray-600 text-center transition-all duration-300 group-hover:text-indigo-600">
                          {numberWithComma(price)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className=" col-span-12 xl:col-span-4 bg-gray-50 w-full max-xl:px-6 max-w-3xl xl:max-w-lg mx-auto lg:pl-8 py-24">
              <h2 className="font-manrope font-bold text-3xl leading-10 text-black pb-8 border-b border-gray-300">
                Thông tin khách hàng
              </h2>
              <div className="mt-8">
                <div className={styles.coverText}>
                  {error && isEmpty(fullName) ? (
                    <div className={styles.textError}>
                      Họ và tên không được để trống
                    </div>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${styles.inputText} ${
                      error && isEmpty(fullName) ? styles.borderError : ""
                    } `}
                    type="text"
                    placeholder="Họ và tên"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  {error && !emailValidation(email) ? (
                    <div className={styles.textError}>
                      Email chưa đúng fotmat
                    </div>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${styles.inputText} ${
                      error && !emailValidation(email) ? styles.borderError : ""
                    } `}
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {error && !phoneValidation(phone) ? (
                    <div className={styles.textError}>
                      Số điện thoại chưa đúng
                    </div>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${styles.inputText} ${
                      error && !phoneValidation(phone) ? styles.borderError : ""
                    } `}
                    type="text"
                    placeholder="Số điện thoại"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {error && isEmpty(address) ? (
                    <div className={styles.textError}>
                      Địa chỉ không được để trống
                    </div>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${styles.inputText} ${
                      error && isEmpty(address) ? styles.borderError : ""
                    } `}
                    type="text"
                    placeholder="Địa chỉ"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  {error && (selectProvince < 1 || selectDistrict < 1) ? (
                    <div className={styles.textError}>
                      Vui lòng chọn tỉnh thành, quận huyên
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={styles.coverSelect}>
                    <select
                      className={`${styles.select} ${
                        error && selectProvince < 1 ? styles.borderError : ""
                      } `}
                      onChange={(e) =>
                        onChaneProvince(parseInt(e.target.value))
                      }
                      value={selectProvince}
                    >
                      <option value={0}>Chọn tỉnh/thành</option>
                      {provinces?.map((value) => {
                        return (
                          <option key={value.id} value={value.id}>
                            {value.province_name}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      className={`${styles.select} ${
                        error && selectDistrict < 1 ? styles.borderError : ""
                      } `}
                      value={selectDistrict}
                      onChange={(e) =>
                        setSelectDistrict(parseInt(e.target.value))
                      }
                    >
                      <option value={0}>Chọn quận/huyện</option>
                      {districts?.map((value) => {
                        return (
                          <option key={value.id} value={value.id}>
                            {value.district_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="flex items-center justify-between py-8">
                  <p className="font-medium text-xl leading-8 text-black">
                    Tổng tiền thanh toán
                  </p>
                  <p className="font-semibold text-xl leading-8 text-indigo-600">
                    {numberWithComma(price)}
                  </p>
                </div>
                <button
                  className="w-full text-center bg-indigo-600 rounded-xl py-3 px-6 font-semibold text-lg text-white transition-all duration-500 hover:bg-indigo-700"
                  onClick={() => onCompleted()}
                >
                  Hoàn tất đơn hàng
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ErrorPopup
        visible={!isEmpty(errorMessage)}
        title="Thông tin tạo đơn hàng"
        mesages={errorMessage}
        onClose={onCloseError}
      />
    </div>
  );
}
