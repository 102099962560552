/* eslint-disable @typescript-eslint/no-explicit-any */
const commomHeaders = {
  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
};
const urlApi = "/api";
// const urlApi = "http://127.0.0.1:8088/api";

const responseProcess = (response) => {
  switch (response.status) {
    case 200: {
      return response.json();
    }
    case 201: {
      return response.json();
    }
    case 500: {
      // eslint-disable-next-line no-throw-literal
      throw "Internal Server Error";
    }

    case 422: {
      return response.json().then((res) => {
        throw res;
      });
    }

    default: {
      // eslint-disable-next-line no-throw-literal
      throw "Error response from server";
    }
  }
};

export const get = async (url, autoAppendQuery = true) => {
  if (autoAppendQuery) {
    const queryString = window.location.search.substring(1);
    if (queryString) {
      // Already has parameter
      if (url.includes("?")) {
        url += "&";
      } else {
        url += "?";
      }
      url += queryString;
    }
  }

  const response = await fetch(urlApi + url, {
    headers: commomHeaders,
  });

  return responseProcess(response);
};

export const request = async ({
  url = "",
  method = "POST",
  payload = {},
  ...opts
}) => {
  const response = await fetch(urlApi + url, {
    headers: commomHeaders,
    method: method,
    body: ["GET", "HEAD"].includes(method)
      ? undefined
      : new URLSearchParams(payload).toString(),
    ...opts,
  });

  if (opts.original) {
    return response;
  }

  return responseProcess(response);
};
