import React from "react";
import CountUp from "react-countup";

const About = () => {
  return (
    <div className="px-6 md:px-0">
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 my-10">
        <div className="flex items-center justify-center">
          <div className="mx-4 m-auto">
            <div className="text-4xl font-bold">
              KHÔNG GIAN DI SẢN PHẬT GIÁO ĐỘC ĐÁO
            </div>
            <div className="text-[#637381] font-normal text-base leading-normal mt-4">
              Phật Bảo Nghiêm Trấn là không gian thưởng lãm văn hóa và nghệ
              thuật Phật giáo với hàng trăm Thánh tượng và vật phẩm từ khắp các
              vùng đất có lịch sử và văn hóa Phật giáo lâu đời như Tượng Phật
              Gandhara cổ xưa, Thái Lan, Myanmar và Trung Quốc.
            </div>
            <div className="text-[#637381] font-normal text-base leading-normal mt-2">
              Mỗi Thánh tượng và vật phẩm đều mang những dấu tích và câu chuyện
              đặc biệt nhất là Tượng Phật Gandhara duy nhất, lớn nhất, có tính
              lịch sử bậc nhất, và Phật Bảo Nghiêm Trấn tự hào đây là Thánh
              tượng duy nhất tại Việt Nam.
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="overflow-hidden relative align-bottom inline-block w-full h-[280px] md:h-[360px] rounded-xl">
            <img
              src="https://phatbaonghiemtran.com/api/images/about/Hinh1.JPG"
              alt="hinh 1"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="overflow-hidden relative align-bottom inline-block w-full h-[280px] md:h-[360px] rounded-xl">
            <img
              src="https://phatbaonghiemtran.com/api/images/about/Hinh2.JPG"
              alt="hinh 2"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="overflow-hidden relative align-bottom inline-block w-full h-[280px] md:h-[360px] rounded-xl">
            <img
              src="https://phatbaonghiemtran.com/api/images/about/Hinh3.JPG"
              alt="hinh 3"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="overflow-hidden relative align-bottom inline-block w-full h-[280px] md:h-[360px] rounded-xl">
            <img
              src="https://phatbaonghiemtran.com/api/images/about/Hinh4.JPG"
              alt="hinh 4"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 my-10">
        <div className="grid grid-cols-2 gap-2">
          <div className="overflow-hidden relative align-bottom inline-block w-full h-[280px] md:h-[360px] rounded-xl">
            <img
              src="https://phatbaonghiemtran.com/api/images/about/IMG_4590.jpg"
              alt="hinh 1"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="overflow-hidden relative align-bottom inline-block w-full h-[280px] md:h-[360px] rounded-xl">
            <img
              src="https://phatbaonghiemtran.com/api/images/about/IMG_4701.jpg"
              alt="hinh 2"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="overflow-hidden relative align-bottom inline-block w-full h-[280px] md:h-[360px] rounded-xl">
            <img
              src="https://phatbaonghiemtran.com/api/images/about/IMG_4673.jpg"
              alt="hinh 3"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="overflow-hidden relative align-bottom inline-block w-full h-[280px] md:h-[360px] rounded-xl">
            <img
              src="https://phatbaonghiemtran.com/api/images/about/IMG_4527.jpg"
              alt="hinh 4"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="mx-4 m-auto">
            <div className="text-[#e78f1d] text-xl">
              Về Phật Bảo Nghiêm Trấn
            </div>
            <div className="text-black font-semibold text-2xl">
              Không gian triển lãm
            </div>
            <div className="text-2xl font-bold text-[#0825f3] ">
              Lịch sử, văn hóa Phật giáo
            </div>
            <div className="text-4xl font-bold">
              Lần đầu có mặt tại VIỆT NAM
            </div>
            <div className="text-[#637381] font-normal text-base leading-normal mt-4">
              <b>&#8220;Phật Bảo&#8221;</b>, ngoài ý nghĩa Phật Bảo trong Tam
              Bảo, còn là những điều trân quý nhất của Phật giáo như thánh
              tượng, thánh bảo, và pháp khí. &#8220;Nghiêm Trấn&#8221; là nơi
              giữ gìn và bảo tồn những giá trị quý giá của Phật giáo một cách
              đúng đắn và oai nghiêm.
            </div>
            <div className="flex mt-5">
              <div className="bg-slate-100 rounded-lg p-6 text-center mr-2">
                <div className="text-xl font-semibold">
                  <CountUp duration={10} className="counter" end={5000} />+
                </div>
                <div className="text-base">Lượt theo dõi</div>
              </div>
              <div className="bg-slate-100 rounded-lg p-6 text-center">
                <div className="text-xl font-semibold">100%</div>
                <div className="text-base">lượt đề xuất từ khách tham quan</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
