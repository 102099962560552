const MapSvg = ({ size = "20", fill = "#7D7D80", ...props }) => (
  <svg
    {...props}
    viewBox="0 0 26 28"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
  >
    <g>
      <path d="M7,20c0,1.71,2.58,3,6,3c3.42,0,6-1.29,6-3c0-1.087-1.044-2.002-2.681-2.526c-0.226,0.307-0.443,0.597-0.653,0.869   C17.086,18.729,18,19.386,18,20c0,0.944-2.139,2-5,2c-2.861,0-5-1.056-5-2c0-0.614,0.914-1.271,2.334-1.658   c-0.21-0.271-0.428-0.562-0.653-0.869C8.044,17.998,7,18.913,7,20z"></path>
      <path d="M13,27c7.289,0,13-2.855,13-6.5c0-2.699-3.135-4.961-7.719-5.956c-0.189,0.313-0.38,0.617-0.573,0.916   C21.951,16.318,25,18.28,25,20.5c0,2.981-5.495,5.5-12,5.5c-6.505,0-12-2.519-12-5.5c0-2.22,3.049-4.182,7.292-5.04   c-0.193-0.299-0.385-0.603-0.573-0.916C3.135,15.539,0,17.801,0,20.5C0,24.145,5.711,27,13,27z"></path>
      <path d="M13,20c0,0,7-7.692,7-13.077C20,3.1,16.866,0,13,0C9.134,0,6,3.1,6,6.923C6,12.308,13,20,13,20z M13,4   c1.657,0,3,1.343,3,2.999c0,1.657-1.343,3-3,3c-1.657,0-3-1.343-3-3C10,5.343,11.343,4,13,4z"></path>
    </g>
  </svg>
);

export default MapSvg;
