import parse from "html-react-parser";
import styles from "./styles.module.css";
import TextButton from "../Text/textButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  compareDateNowNotTime,
  formatDate,
  formatTimeRange,
  isNowDate,
  isTimeLessThanOrEqualToNow,
  numberWithComma,
  passToday,
} from "../../utils/formatter";
import API from "../../api";

export default function ProductInformation({ data }) {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(-1);
  const [isNotSelectExhibition, setIsNotSelectExhibition] = useState(false);
  const [selectExhibition, setSelectExhibition] = useState({
    code: null,
    time: null,
  });
  const [ticketDates, setTicketDates] = useState([]);
  const [ticketTimes, setTicketTimes] = useState([]);
  const [slot, setSlot] = useState(-1);

  const onChangePrice = (number) => {
    const newQuantity = quantity + number;
    const amount = parseInt(data?.selling_price || "0");

    if (newQuantity > slot) {
      return;
    }

    if (newQuantity < 1) {
      setQuantity(1);
      setPrice(amount || 0);
    } else {
      setQuantity(newQuantity);
      setPrice(amount * newQuantity);
    }
  };

  const get_ticket_days = (ticket_code) => {
    API.getTicketDays(ticket_code).then((res) => {
      if (res.success) {
        const listDays = res.data;
        setTicketDates(listDays);
        const dayCode = listDays.find((x) => compareDateNowNotTime(x.day));
        if (dayCode) {
          get_ticket_time(dayCode.code);
        }
      }
    });
  };

  const get_ticket_time = (day_code) => {
    API.getTicketTime(day_code).then((res) => {
      if (res.success) {
        setTicketTimes(res.data);
      }
    });
  };

  useEffect(() => {
    if (data) {
      setPrice(parseInt(data?.selling_price || "0"));
      get_ticket_days(data.ticket_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const checkSelectExhibition = () => {
    setIsNotSelectExhibition(false);
    if (!selectExhibition.code || !selectExhibition.time) {
      setIsNotSelectExhibition(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return false;
    }
    return true;
  };

  const onPayment = () => {
    if (checkSelectExhibition()) {
      const ticketDate = ticketDates.find(
        (x) => x.code === selectExhibition.code
      );
      const ticketTime = ticketTimes.find(
        (x) => parseInt(x.id) === parseInt(selectExhibition?.time || "0")
      );

      const dataAddToCart = {
        ticket_code: data?.ticket_code || "",
        ticket_day_code: selectExhibition.code || "",
        ticket_time_id: parseInt(selectExhibition.time || "0"),
        quantity: quantity,
        name: data?.ticket_name || "",
        amount: parseInt(data?.selling_price || "0"),
        ticketDate: ticketDate,
        ticketTime: ticketTime,
        urlImage: data?.url_image_mobile || "",
      };

      localStorage.setItem("ticketData", JSON.stringify(dataAddToCart));
      navigate(`/payments`);
    }
  };

  const onClickDate = async (key) => {
    API.getTicketTime(key).then((res) => {
      if (res.success) {
        const timeList = res.data;
        setTicketTimes(timeList);

        const ticketDate = ticketDates.find((x) => x.code === key);

        const timeChoose = timeList.find(
          (x) =>
            ticketDate &&
            passToday(ticketDate.day) &&
            !isTimeLessThanOrEqualToNow(x.from_time)
        );

        if (timeChoose) {
          setSelectExhibition({
            code: key,
            time: timeChoose.id.toString(),
          });

          const calSlot = timeChoose.slot - timeChoose.booked;

          setSlot(calSlot);
        } else {
          setSelectExhibition({
            code: key,
            time: null,
          });
          setSlot(0);
        }
      } else {
        setSelectExhibition({
          code: key,
          time: null,
        });
        setSlot(0);
      }
    });
  };

  const onClickTime = (key) => {
    const timeChoose = ticketTimes.find(
      ({ id }) => parseInt(id) === parseInt(key)
    );

    if (timeChoose) {
      const calSlot = timeChoose.slot - timeChoose.booked;
      setSlot(calSlot);
    }
    setSelectExhibition({
      code: selectExhibition.code,
      time: key,
    });
  };

  return (
    <div className={styles.cover}>
      <div className={styles.coverImage}>
        {data && (
          <img
            src={data?.url_image_mobile}
            alt="img"
            className={styles.imgSubBanner}
          />
        )}
        <h1 className={styles.header}>{data?.ticket_name}</h1>
        <div className={styles.noteWeb}>
          <div className={styles.content}>{parse(data?.note || "")}</div>
        </div>
      </div>

      <div className={styles.inforTime}>
        <div className={styles.coverTitle}>
          <h1 className={styles.title}>Chọn ngày & giờ triển lãm</h1>
        </div>
        <div className={styles.coverTime}>
          <div className={styles.exhibitionDate}>
            <div className="h-[30px]">
              {isNotSelectExhibition && (
                <div className="text-[rgb(var(--background-red))] text-sm">
                  Vui lòng chọn thời gian tham gia
                </div>
              )}
            </div>
            <div className="text-md font-semibold uppercase text-[rgb(var(--background-red))]">
              Ngày triển lãm
            </div>
            <div className={styles.exhibitionDateItem}>
              {ticketDates.map((value, index) => {
                const disableText = !compareDateNowNotTime(value.day);

                return (
                  <TextButton
                    value={value.code}
                    text={formatDate(value.day)}
                    onClick={onClickDate}
                    isActivity={selectExhibition.code === value.code}
                    key={`show-date-booking-${value.code}-${index}`}
                    disable={disableText}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.exhibitionTime}>
            <div className="text-md font-semibold uppercase text-[rgb(var(--background-red))]">
              Giờ triển lãm
            </div>
            <div className={styles.exhibitionTimeItem}>
              {ticketTimes.map((value, index) => {
                const ticketDate = ticketDates.find(
                  (x) => x.code === selectExhibition.code
                );
                const disable =
                  ticketDate &&
                  isNowDate(ticketDate.day) &&
                  isTimeLessThanOrEqualToNow(value.from_time);

                return (
                  <TextButton
                    value={value.id.toString()}
                    text={formatTimeRange(value.from_time, value.to_time)}
                    onClick={onClickTime}
                    isActivity={selectExhibition.time === value.id.toString()}
                    key={`show-time-booking-${value.day_code}-${index}`}
                    disable={disable}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.slot}>
            <div>Vé còn trống: </div>
            <div className={styles.slotNumber}>{slot < 0 ? "--" : slot}</div>
          </div>
          <div className={styles.exhibitionQuantity}>
            <div>Số lượng</div>
            <div className={styles.calculate}>
              <button
                className={`${styles.minus} ${
                  quantity <= 1 ? styles.buttonDisable : ""
                }`}
                onClick={() => onChangePrice(-1)}
              >
                -
              </button>
              <div className={styles.quantity}>{quantity}</div>
              <button
                className={`${styles.pluss} ${
                  quantity >= slot ? styles.buttonDisable : ""
                }`}
                onClick={() => onChangePrice(1)}
              >
                +
              </button>
            </div>
          </div>
          <div className={styles.exhibitionPrice}>
            <div>Giá vé</div>
            <div className="text-xl font-bold">{numberWithComma(price)}đ</div>
          </div>
          <div className={styles.exhibitionFooter}>
            <div className={styles.button}>
              {/* <button
                data-modal-hide="default-modal"
                type="button"
                className={styles.buttonAdd}
                onClick={() => onAddToCart()}
              >
                Thêm vào giỏ hàng
              </button> */}
              <button
                data-modal-hide="default-modal"
                type="button"
                className="py-2.5 px-5 ms-3 text-sm uppercase rounded-lg border mr-3 font-semibold bg-[rgb(var(--background-red))] text-[white] w-full"
                onClick={() => onPayment()}
              >
                Đặt vé
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.noteMb}>
        <div className={styles.note}>Lưu ý:</div>
        <div className={styles.content}>{parse(data?.note || "")}</div>
      </div>
    </div>
  );
}
