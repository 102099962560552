const ClockSvg = ({ size = "20", fill = "none", ...props }) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    strokeWidth="2"
    stroke="currentColor"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <polyline points="12 6 12 12 16 14"></polyline>
  </svg>
);

export default ClockSvg;
