import React from "react";
import LogoVPN from "./assets/images/logo-no-bg-text-white.png";
import TiktokSvg from "./assets/svg/tiktokSvg";
import Facebooksvg from "./assets/svg/facebooksvg";

const Footer = () => {
  return (
    <div className="bg-[rgb(var(--background-red))] pt-10">
      <div className="max-w-screen-xl w-full mx-auto px-6 xl:px-0 grid grid-rows-6 sm:grid-rows-1 grid-flow-row sm:grid-flow-col grid-cols-3 sm:grid-cols-12 gap-4">
        <div className="row-span-6 sm:col-span-4 col-start-1 col-end-2 sm:col-end-2 flex flex-col items-start ">
          <img src={LogoVPN} alt="logo" />
        </div>
        <div className=" row-span-6 sm:col-span-4 col-start-2 col-end-4 sm:col-start-3 sm:col-end-6 flex flex-col">
          <p className="text-white mb-4 font-medium uppercase">
            Điều khoản và chính sách
          </p>
          <ul className="text-white pl-2">
            <li className="my-2 cursor-pointer transition-all text-sm">
              <a href="chinh-sach" className="hover:underline text-white">
                Chính sách bảo mật
              </a>
            </li>
            <li className="my-2 cursor-pointer transition-all text-sm">
              <a href="dieu-khoan" className="hover:underline text-white">
                Điều khoản dịch vụ
              </a>
            </li>
            <li className="my-2 cursor-pointer transition-all text-sm">
              <a href="/chinh-sach" className="hover:underline text-white">
                Chính sách đổi trả
              </a>
            </li>
          </ul>
        </div>
        <div className="row-span-4 sm:col-span-2 col-start-1 col-end-4 sm:col-start-6 sm:col-end-10 flex flex-col">
          <p className="text-white mb-4 font-medium uppercase">
            Chăm sóc khách hàng
          </p>
          <ul className="text-white pl-2">
            <li className="my-2 cursor-pointer transition-all text-sm">
              Email:{" "}
              <a
                href="mailto:phatbaonghiemtran@gmail.com"
                className="hover:underline text-white"
              >
                phatbaonghiemtran@gmail.com
              </a>
            </li>
            <li className="my-2 cursor-pointer transition-all text-sm">
              Hotline:{" "}
              <a href="tel:0354451001" className="hover:underline text-white">
                035 445 1001
              </a>
            </li>
          </ul>
        </div>
        <div className="row-span-3 sm:col-span-2 col-start-1 col-end-4 sm:col-start-10 sm:col-end-13 flex flex-col">
          <p className="text-white mb-4 font-medium uppercase">
            Kết nối với chúng tôi
          </p>
          <div className="flex mt-4 sm:mt-0">
            <a
              href="https://www.facebook.com/phatbaonghiemtran/"
              className="text-white border p-2 rounded mr-2"
              target="blank"
            >
              <Facebooksvg className="h-6 w-6" />
              <span className="sr-only">Facebook page</span>
            </a>
            <a
              href="https://www.tiktok.com/@phatbaonghiemtran?_t=8pqQqsQlJQI&_r=1"
              className="text-white border p-2 rounded"
              target="blank"
            >
              <TiktokSvg className="h-6 w-6" />
              <span className="sr-only">Tiktok page</span>
            </a>
          </div>
        </div>
      </div>
      <hr className="border-white-500 mt-2" />
      <div className="max-w-screen-xl w-full mx-auto px-6 sm:px-8 lg:px-16 py-2 xl:pb-5">
        <span className="text-sm sm:text-center text-white">
          © 2024{" "}
          <a href="/" className="hover:underline text-white">
            Phat Bao Nghiem Tran
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </div>
  );
};

export default Footer;
